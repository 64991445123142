const ordinal = text =>
  text.replace(/(\d+(st|nd|rd|th))/g, '<span class="ordinal">$1</span>')

const fraction = text =>
  text.replace(
    /(\d+)\/(\d+)/g,
    '<span class="numr">$1</span>&frasl;<span class="dnom">$2</span>'
  )

const numbers = [ordinal, fraction]

export default text => numbers.reduce((acc, f) => f(acc), text)
