import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import css from '@emotion/css'
import { deepOrange as themeColor } from 'material-colors'
import HamburgerMenu from './HamburgerMenu'

const Layout = props => {
  const { location, children, cover } = props
  const rootPath = `${__PATH_PREFIX__}/`
  const [showMenu, setShowMenu] = React.useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              routes {
                name
                path
              }
            }
          }
        }
      `}
      render={data => {
        const header = (
          <div
            css={css`
              @media (max-width: 768px) {
                display: none;
              }
            `}
          >
            {location.pathname !== rootPath && (
              <h1
                style={{
                  ...scale(0.9),
                }}
              >
                <Link to="/">{data.site.siteMetadata.title}</Link>
              </h1>
            )}
            <div
              css={css`
                display: flex;
                margin-top: ${rhythm(-4 / 5)};
                flex-direction: column;
              `}
            >
              {data.site.siteMetadata.routes.map(route => (
                <h3
                  key={route.path}
                  style={{
                    marginBottom: rhythm(-3 / 4),
                  }}
                >
                  <Link to={route.path}>{route.name}</Link>
                </h3>
              ))}
            </div>
          </div>
        )
        const mobileHeader = (
          <nav
            css={css`
              display: flex;
              height: ${rhythm(2)};
              justify-content: space-between;
              align-items: center;
              padding: ${rhythm(1 / 2)};
              background-color: ${themeColor[500]};
              @media (min-width: 768px) {
                display: none;
              }
            `}
          >
            <h2 style={{ margin: 0 }}>
              <Link to="/" style={{ color: 'white' }}>
                {data.site.siteMetadata.title}
              </Link>
            </h2>
            <HamburgerMenu
              isOpen={showMenu}
              menuClicked={toggleMenu}
              closedColor="white"
              openColor={themeColor[500]}
              strokeWidth={3}
              height={20}
              width={30}
              zIndex={101}
            />
          </nav>
        )
        const mobileMenu = (
          <div
            css={css`
              position: fixed;
              z-index: ${showMenu ? 100 : -1};
              top: 0;
              left: 0;
              width: 100%;
              height: 100vh;
              margin: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: ${themeColor[100]};
              opacity: ${showMenu ? 100 : 0};
              transition-property: opacity;
              transition-duration: 0.15s;
              touch-action: none;
              @media (min-width: 768px) {
                display: none;
              }
            `}
          >
            {[
              ...data.site.siteMetadata.routes,
              { name: 'Home', path: '/' },
            ].map(route => (
              <h3
                key={route.path}
                style={{
                  marginBottom: rhythm(-1),
                  ...scale(1.3),
                }}
              >
                {location.pathname === route.path ? (
                  <a onClick={toggleMenu} style={{ cursor: 'pointer' }}>
                    {route.name}
                  </a>
                ) : (
                  <Link to={route.path}>{route.name}</Link>
                )}
              </h3>
            ))}
          </div>
        )
        return (
          <div
            style={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {mobileHeader}
            {mobileMenu}
            {cover}
            <div
              className="layoutBox"
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: rhythm(23),
                padding: `${rhythm(1 / 2)} ${rhythm(1)}`,
                width: '100%',
              }}
            >
              <div style={{ paddingBottom: rhythm(1), flexGrow: 1 }}>
                <header className="hang-left">{header}</header>
                {children}
              </div>
              <footer>
                <small>
                  © Aaron Olsen, Daisy Gruchy, Evan Fortin, Joshua Zelek, Joy
                  Henhoeffer {new Date().getFullYear()}
                </small>
              </footer>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Layout
