const pull = (className, content) =>
  `<span class="pull-${className}">${content || ``}</span>`

const push = (className, content) =>
  `<span class="push-${className}">${content || ``}</span>`

const doubleWidth = [
  '&quot;',
  '"',
  '“',
  '„',
  '”',
  '&ldquo;',
  '&OpenCurlyDoubleQuote;',
  '&#8220;',
  '&#x0201C;',
  '&rdquor;',
  '&rdquo;',
  '&CloseCurlyDoubleQuote;',
  '&#8221;',
  '&ldquor;',
  '&bdquo;',
  '&#8222;',
]
const singleWidth = ["'", '&prime;', '&apos;', '&lsquo;', '&rsquo;', '‘', '’']

export default (text, node, $) => {
  if (text.length < 2) return text

  // Remove consecutive double spaces then create
  // array of distinct words.
  var words = text
    .split(' ')
    .join(' ')
    .split(' ')

  for (var i in words) {
    for (var b in singleWidth) {
      var punctuation = singleWidth[b]

      if (words[i].slice(0, punctuation.length) === punctuation) {
        var insert = pull('single', punctuation)

        if (words[i - 1]) {
          words[i - 1] = words[i - 1] + push('single')
        } else if (hasAdjacentText($, node)) {
          insert = push('single') + insert
        }

        words[i] = insert + words[i].slice(punctuation.length)
      }
    }

    for (var c in doubleWidth) {
      var punctuation = doubleWidth[c]

      if (words[i].slice(0, punctuation.length) === punctuation) {
        var insert = pull('double', punctuation)

        if (words[i - 1]) {
          words[i - 1] = words[i - 1] + push('double')
        } else if (hasAdjacentText($, node)) {
          insert = push('double') + insert
        }

        words[i] = insert + words[i].slice(punctuation.length)
      }
    }
  }

  text = words.join(' ')

  return text
}

function hasAdjacentText($, node) {
  // the nearest sibling to this text node
  // you can have two adjacent text nodes
  // since they'd just be one node.

  // however, the previous sibling could end with a text node
  // if so, we need to add the spacer to prevent overlap
  if (node.prev && node.prev.children && node.prev.children.length) {
    var lastChild = node.prev.children.slice(-1)[0]

    if (lastChild && lastChild.type === 'text') {
      return true
    }
  }

  if (!$(node).parent() || !$(node).parent().length) return false

  var parentPrev = $(node).parent()[0].prev

  // Ensure the parent has text content
  // and is not simply a newline separating tags
  if (parentPrev && parentPrev.type === 'text' && parentPrev.data.trim()) {
    return true
  }

  return false
}
