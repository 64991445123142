import Typography from 'typography'
import { deepOrange as themeColor } from 'material-colors'

const MOBILE_MEDIA_QUERY = '@media (max-width: 768px)'
const TINY_MEDIA_QUERY = '@media (max-width: 460px)'

const typography = new Typography({
  baseFontSize: '1px',
  baseLineHeight: 1.35,
  blockMarginBottom: 0.8,
  headerFontFamily: ['freight-sans-pro', 'sans-serif'],
  bodyFontFamily: ['freight-text-pro', 'Georgia', 'serif'],
  overrideStyles: ({ rhythm }, options) => {
    return {
      html: { fontSize: '2.1vw' },
      '@media (min-width: 1000px)': { html: { fontSize: rhythm(1) } },
      h1: { color: 'hsla(0,0%,0%,0.75)' },
      h2: { color: 'hsla(0,0%,0%,0.775)' },
      h3: { color: 'hsla(0,0%,0%,0.8)' },
      'h2,h3': { marginTop: rhythm(1), marginBottom: rhythm(1 / 3) },
      'h4,h5,h6': { 'font-variant': 'small-caps', marginBottom: rhythm(1 / 3) },
      'h1,h2,h3,h4,h5,h6': { hyphens: 'none' },
      a: {
        color: themeColor[400],
        'text-decoration': 'none',
        'transition-property': 'background',
        'transition-duration': '0.2s',
        hyphens: 'none',
      },
      'a:hover': { background: themeColor[50], borderRadius: rhythm(1 / 5) },
      ul: { listStyleType: 'none' },
      'ul:not(.no-bullet) li:before': {
        content: '"\u2b1b"',
        textAlign: 'right',
        marginLeft: rhythm(-1),
        float: 'left',
        width: rhythm(1),
        transform: 'scale(0.4)',
      },
      ol: { counterReset: 'counter', listStyleType: 'none' },
      'ol li:before': {
        content: 'counter(counter) " "',
        counterIncrement: 'counter',
        textAlign: 'right',
        marginLeft: rhythm(-1),
        float: 'left',
        width: rhythm(1),
      },
      blockquote: {
        paddingLeft: rhythm(2 / 3),
        lineHeight: '1.25em',
        fontSize: '0.97em',
        marginLeft: rhythm(-3 / 3),
        marginRight: 0,
        borderLeft: `${
          rhythm(1 / 3) // Lighten larger headers so they don't contrast so much with the body.
        } solid ${themeColor[100]}`,
        fontStyle: 'italic',
      },
      'blockquote em': { fontStyle: 'normal' },
      'blockquote > :last-child': { marginBottom: 0 },
      'blockquote footer': {
        marginTop: rhythm(-1 / 2),
        textAlign: 'right',
        fontStyle: 'normal',
      },
      'blockquote footer em': {
        fontStyle: 'italic',
      },
      'hr:after': { display: 'inline-block', content: '&#xe052;' },
      '.push-double': { marginRight: '0.425em' },
      '.pull-double': { marginLeft: '-0.425em' },
      '.push-single': { marginRight: '0.262em' },
      '.pull-single': { marginLeft: '-0.262em' },
      '.small-caps': { 'font-variant': 'all-small-caps' },
      '.ordinal': { 'font-variant-numeric': 'ordinal' },
      '.fraction': { 'font-variant-numeric': 'diagonal-fractions' },
      '.numr': { 'font-feature-settings': '"numr"' },
      '.dnom': { 'font-feature-settings': '"dnom"' },
      'aside, .hang-left': {
        display: 'block',
        fontFamily: 'freight-sans-pro, sans-serif',
        fontWeight: 400,
        letterSpacing: '0.015em',
        lineHeight: '1.25em',
        fontSize: '0.85rem',
        color: '#555',
        hyphens: 'none',
        textAlign: 'right',
        width: '45%',
        transform: 'translateX(calc(-100% - 2.5em))',
        height: 0,
      },
      '.photo-index-grid': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [MOBILE_MEDIA_QUERY]: {
        html: { 'font-size': rhythm(4 / 5) },
        'aside, .hang-left': {
          height: 'initial',
          transform: 'none',
          width: 'initial',
          textAlign: 'left',
        },
        aside: {
          display: 'block',
          border: '1px solid #ccc',
          borderLeft: `3px solid ${themeColor[200]}`,
          padding: '0.5em',
          marginBottom: options.blockMarginBottom + 'rem',
        },
        '.nav-links': {
          display: 'flex',
          flexDirection: 'row',
          marginTop: '-1em',
        },
        '.photo-index-grid': {
          gridTemplateColumns: '1fr 1fr',
        },
      },
      [TINY_MEDIA_QUERY]: {
        '.nav-links': {
          flexDirection: 'column',
          marginBottom: '1em',
          marginTop: '-1em',
        },
        '.photo-index-grid': {
          gridTemplateColumns: '1fr',
        },
      },
      '@media (min-width: 769px)': {
        '.layoutBox': { transform: 'translateX(calc(calc(45% + 1.25em) / 2))' },
        'ul, ol': { marginLeft: 0 },
      },
    }
  },
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
