const linefeedToSpace = text => text.replace(/\n/g, ' ')
const newLines = [linefeedToSpace]

const emDash = text => text.replace(/---/g, '&mdash;')
const enDash = text => text.replace(/--/g, '&#8288;&ndash;&#8288;')
const noLineBreakAfterEmDash = text => text.replace(/(&mdash;|—)/g, '&#8288;&mdash;')
const dashes = [emDash, enDash, noLineBreakAfterEmDash]

const ellipses = text => text.replace(/\.\.\./g, '…')
const symbols = [ellipses]

const NBSP_CHAR = '&nbsp;'
const NBSP_PUNCTUATION_START = /([«¿¡]) /g
const NBSP_PUNCTUATION_END = / ([\!\?:;\.,‽»])/g
const nbspStart = text => text.replace(NBSP_PUNCTUATION_START, '$1' + NBSP_CHAR)
const nbspEnd = text => text.replace(NBSP_PUNCTUATION_END, NBSP_CHAR + '$1')
const nbsp = [nbspStart, nbspEnd]

const punctuation = [...newLines, ...dashes, ...symbols, ...nbsp]

export default text => punctuation.reduce((acc, f) => f(acc), text)
