import cheerio from 'cheerio'

const escapeHtmlSymbols = text =>
  text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')

const escapeQuotes = text => text.replace(/&#39;/g, "'").replace(/&quot;/g, '"')

const IGNORE =
  'head, code, pre, script, style, [class^="pull-"], [class^="push-"], .small-caps, .no-process'

export default (html, f) => {
  var $ = cheerio.load(html, { decodeEntities: false })

  $(':root').each(function() {
    findTextNodes(this)
  })

  function findTextNodes(node) {
    if ($(node).is(IGNORE)) return false

    $(node)
      .contents()
      .each(function() {
        const childNode = this

        if (childNode.nodeType === 3) {
          const text = escapeHtmlSymbols(escapeQuotes(childNode.data))
          childNode.data = text
          $(childNode).replaceWith(f(text, childNode, $))
        } else {
          findTextNodes(childNode)
        }
      })
  }

  return $.html()
}
