import Hypher from 'hypher'

import gbEnglish from './hypher-patterns/en-gb'

const h = new Hypher(gbEnglish)

export default text => {
  var words = text.split(' ')

  for (var i in words) {
    var word = words[i]

    if (word.slice(0, 1).toUpperCase() === word.slice(0, 1)) continue

    words[i] = h.hyphenateText(word)
  }

  return words.join(' ')
}
