import smallCaps from './smallCaps'
import punctuation from './punctuation'
import quotes from './quotes'
import hyphenate from './hyphenate'
import ordinals from './numbers'
import hangingPunctuation from './hangingPunctuation'
const modules = [
  punctuation,
  smallCaps,
  quotes,
  hyphenate,
  ordinals,
  hangingPunctuation,
]

import eachTextNode from './eachTextNode'

export default html => modules.reduce(eachTextNode, html)
